<template>
  <Suspense>
    <div class="accent-blue-600" :class="appConfig.is_demo_mode ? 'flex h-screen flex-col' : 'h-screen'">
      <DemoBanner v-if="appConfig.is_demo_mode" />
      <div :class="[appConfig.is_demo_mode ? 'flex-grow overflow-auto' : 'h-screen', { safari }]">
        <ErrorPage v-if="errorCode" :code="errorCode" />
        <router-view v-else-if="!errorRedirect" v-slot="{ Component }">
          <Suspense suspensible>
            <component :is="Component" />
          </Suspense>
        </router-view>
        <!-- work around vue-router bug with using teleport to the same container element -->
        <div class="hidden" />
      </div>
    </div>
  </Suspense>
</template>

<script setup lang="ts">import { ref as _ref } from 'vue';

import { is } from "ts-safe-cast";
import { onErrorCaptured } from "vue";
import { useRouter } from "vue-router";
import { DataError } from "$app/composables/useData";
import { configKey, useInject } from "$app/composables/useInject";
import ErrorPage from "$app/ErrorPage.vue";
import DemoBanner from "$app/Home/DemoBanner.vue";
import { ResponseError } from "$app/utils/request";
const safari = navigator.vendor.includes("Apple");
let errorCode = _ref<404 | 500 | null>(null);
const router = useRouter();
let errorRedirect = _ref(false);
const appConfig = useInject(configKey);
onErrorCaptured<unknown>((error) => {
    if (error instanceof DataError) {
        if (error.response.status === 401) {
            void router.replace("/logout");
            errorRedirect.value = true;
        }
        else if (error.response.status === 403 && is<{
            redirect_path: string;
        }>(error.json, [5, [0, "redirect_path", 0]])) {
            void router.replace(error.json.redirect_path);
            errorRedirect.value = true;
        }
        else
            errorCode.value = error.response.status === 404 ? 404 : 500;
        return false;
    }
    else if (error instanceof ResponseError) {
        errorCode.value = 500;
    }
});
router.afterEach(() => {
    errorRedirect.value = false;
    errorCode.value = null;
});
</script>
